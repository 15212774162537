.form-block-outer {
    border: 1px solid #e7e7e7;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    width: 800px;
}
.frm-area {
    padding: 20px;
    background: #fff;
}
.form-block-outer.reg-wider {
    width: 620px;
}
.form-group {
    margin-top: 10px;
}
img.logo-one {
    width: 25%;
    margin: auto;
}
img.logo-two {
    width: 100%;
}
.full-login {
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
}
.bckcjd1 {
    background-image: url('https://cjmarketing.co/image_directory/cjdback1.jpg');
}
.bckcjd2 {
    background-image: url('https://cjmarketing.co/image_directory/cjdback2.jpg');
}
.text-form {
    font-size: 10px;
}