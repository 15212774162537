body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn-full{
  width: 100%;
}
.btn-cjdsa {
  background: #F36C21 ;
  color: #ffffff;
}
.btn-cjdsa:hover {
  background: #9f4717;
  color: #ffffff;
}
a{
  color:#F36C21;
  text-decoration: none;
  font-size: 12px;
}
a:hover {
  color: #9f4717;
}
.openingbanner {
  background-image: url(https://cjmarketing.co/image_directory/cjdbanners01.jpg);
  padding: 45px 0px;
  height: 360px;
  background-position: center;
  background-size: cover;

}
.seach-box-home h2{
  color: #ffffff;
  font-size: 60px;
  font-weight: 900;
}
span.iconSearch {
  padding-right: 10px;
}
img.processing {
  width: 200px;
}
.process-container {
  position: fixed;
  left: 40%;
  top: 30%;
  z-index: 10000;
}

.btn-cjdsa.lg-butt1 {
  padding: 20px 40px;
  border-radius: 30px;
}
.shop-box-container {
  padding: 40px;
}
.row-search {
  display: flex;
  padding: 15px;
}
input.searchingfield {
  background: #e9e9e9;
  border: none;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}
.small-text{
  font-size: 10px;
}
/** Shopping page **/
.product-container-list {
  padding: 15px;
}
.barcode-display1 {
  font-weight: 200;
  padding: 10px;
}
.frame-product {
  border: 1px solid #e9e9e9;
  margin-bottom: 30px;
}
.current-image {
  background: #f7f6f6;
  padding: 10px;
  text-align: center;
  overflow: hidden;
}
img.image-product-1 {
  height: 260px;
}
.content-txt{
  padding: 10px;
}
.title-text {
  font-size: 13px;
  font-weight: 600;
}
.sub-descrpt {
  font-size: 10px;
  color: #646464;
  height: 37px;
}
.pricing-select {
  font-weight: 700;
  color: #F36C21;
  padding-top: 8px;
}
.row.rproduct {
  padding: 10px;
  border: 1px solid #f1f1f1;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  position: relative;
}
.btn-close-product {
  position: absolute;
  top: 30px;
  right: 30px;
}
.product-content {
  padding: 20px 10px;
}
.sub-desc {
  padding: 0px 10px;
  font-size: 13px;
  color: #302f2f;
}
.cl-title-range {
  background: #f36c21;
  color: #fff;
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 20px;
  font-weight: 200;
  border-radius: 20px;
}
.cl-title-range:hover {
  background: #d25c1a;
}
.back-response {
  height: 400px;
  margin-bottom: 25px;
  background-size: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.back-response:hover {
  background-size: 150%;
}

.quantity-sect {
  display: flex;
  padding: 20px;
}
.btncart {
  font-size: 12px;
  border-radius: 15px;
}
.qtyShow {
  width: 40px;
  display: block;
  padding-top: 8px;
  text-align: center;
}
.checkount-title {
  background: #1f1f1f;
  padding: 10px;
  text-align: center;
  font-weight: 600;
  color: #fff;
}

.checkout {
  padding: 0px 20px;
}
.check-sub-section {
  margin-top: 10px;
  padding-bottom: 10px;
}
.check-sub-section.check-cost {
  background: #f9f9f9;
}
.incr-total {
  font-size: 20px;
  text-align: right;
  font-weight: 800;
}
.price-cart {
  padding-left: 15px;
  padding-bottom: 10px;
  font-weight: 100;
}
/** Shopping end **/
.img-full{
  width: 100%;
}
.gray-text {
  color: #828282;
}
.mgbot10{
  margin-bottom: 10px;
}
.mgtop10 {
  margin-top: 10px;
}
.mgtop30 {
  margin-top: 30px;
}
.mgtop60 {
  margin-top: 60px;
}
.mgtop80 {
  margin-top: 80px;
}
.mgleft5 {
  margin-left:5px;
}
.pd10 {
  padding: 10px;
}
.pd20 {
  padding: 20px;
}
.pd30 {
  padding: 30px;
}
.pd60 {
  padding: 60px;
}
/***** Spinner **/
.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(55, 38, 22, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #ff7700 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/****** SPINNER **/
.flexme {
  display: flex;
}
.flex50{
  width: 50%;
}
img.button-loading {
  width: 60px;
}
.nav-isolation {
  position: absolute;
  top: 10px;
  padding: 9px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.nav-isolation .btn.btn-cjdsa {
  padding: 3px 15px;
  height: 40px;
  margin-top: 5px;
}
.btncart:active {
  animation: bounce 0.3s;
}
@keyframes bounce {
  0% {
       transform: scale(1);
     }
     25% {
       transform: scale(0.95);
     }
     50% {
       transform: scale(1.1);
     }
     75% {
       transform: scale(0.98);
     }
     100% {
       transform: scale(1);
     }
}
h4.page-title-base {
  font-weight: 100;
  letter-spacing: 6px;
  font-size: 35px;
}