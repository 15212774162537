img.logo-small {
  width: 40px;
}
nav.navbar.navbar-expand-md.navbar-dark {
  background: #c3bdb5;
}
.nav-search {
  width: 70%;
  background: #9b928e;
  color: #fff;
  padding: 5px;
  border-radius: 9px;
  text-align: center;
}
.topnav-prod-total {
  width: 5%;
  font-size: 10px;
  text-align: center;
}
.topnav-cart {
  width: 5%;
  text-align: center;
}
.topnav-cart a {
  color: #fff;
  font-size: 13px;
}
span.total-Products {
  font-size: 15px;
}
.cart-main {
  background: #c30600;
  padding: 8px;
  border-radius: 8px;
}
span.countTop {
  padding: 11px;
  font-size: 16px;
}
.searchname {
  font-weight: 100;
  font-size: 12px;
}
@media screen and (max-width: 1290px) {

  .nav-search {
      width: 50%;
  }

  topnav-cart, .topnav-prod-total {
      width: 10%;
  }
}

@media screen and (max-width: 500px) {
    .topnav-cart, .topnav-prod-total {
        width: 25%;
    }

    .nav-search {
        width: 95%;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .product-container-list {
        padding: 0px;
    }
}