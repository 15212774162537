
@media screen and (max-width: 1290px) {

}
@media screen and (max-width: 950px) {

}
@media screen and (max-width: 500px) {

    .row-search {
        padding: 30px 10px 5px;
    }
    .process-container {
        left: 25%;
        top: 30%;
    }
    .shop-box-container {
        padding: 75px 20px;
    }

    img.image-product-1 {
        height: 180px;
    }

    .seach-box-home h2 {
        font-size: 30px;
    }

    .btn-cjdsa.lg-butt1 {
        border-radius: 30px;
        padding: 15px 30px;
    }
    
    .back-response {
        height: 240px;
        transition: all .5s ease-in-out;
    }
    h4.page-title-base{
        margin-top: 100px;
    }
}